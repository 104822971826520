<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'WhyUs',
  components: {
    SectionBanner
  },
  data() {
    return {
      sections: [
        {
          Title: "Why Us?",
          Subtitle: "We listen, understand, and help",
          Description: "A lot of lawyers are great talkers. We’re good listeners. By understanding your problem, from your point of view, we can get a holistic view of the issues you're facing.",
          img: require("@/assets/img/Laura2.jpg"),
        },
        {
          TextColumn: true,
          TextColumn1: `<p>Often, the cause may well not just be purely legal. It could be financial, a lack of confidence in decision-making, or just needing a second opinion on a business strategy. We’re someone you can bounce ideas off, and help you plan and take the course of action that makes the best sense for you.</p>
      <div class="spacer"></div>
      <h3 class="text-column__title">Why We Do It</h3>
      <p>It can be hard living in a world where you don’t know what you can and can’t do. Few things in life are certain, we get a good feeling providing some certainty and comfort in life.</p>
      <div class="spacer"></div>
      <h3 class="text-column__title">We’re Lean, Not Big</h3>
      <div class="text-column__image"><img src="/img/Directors.jpg" alt="Directors" /></div>
      <p><strong>Big Law is bloated. </strong>Most law firms operate on a business model developed centuries ago, which isn’t designed for you, today. Those legacy systems have significant fixed costs like large payrolls, pricey real estate, and fancy art collections. Who do you think pays for that?<br>&zwj;<br><strong>Lean Law is agile. </strong>We build teams around projects in real time according to your specific needs. So we’re able to deliver quality legal counsel without the bloated overhead of Big Law.</p>`,
          TextColumn2: false,
        },
        {
          TextColumns: true,
          TextColumns1: `<h4 class="text-column__title--left text-column__title">Them</h4>
            <p><strong>Detached</strong></p>
            <p>Bill and run</p>
            <p><br></p>
            <p><strong>Remote</strong></p>
            <p>Hide behind secretaries and meetings</p>
            <p><br></p>
            <p><strong>Corporate</strong></p>
            <p>You don’t work for the man. Why work with the man?</p>
            <p><br></p>
            <p><strong>Opaque</strong></p>
            <p>Surprises are fun… except when it comes to legal bills.<br>&nbsp</p>
            <p><br></p>
            <p><strong>Slow</strong></p>
            <p>When you bill by the hour, it pays to slow down the process.</p>
            <p><br></p>
            <p><strong>Antiquated</strong></p>
            <p>Old systems don’t work in the new economy.</p>
            <p><br></p>`,
          TextColumns2: `<h4 class="text-column__title text-column__title--left">Us</h4>
            <p><strong>Invested</strong></p>
            <p>80% of our work is repeat business</p>
            <p><br></p>
            <p><strong>Contactable</strong></p>
            <p>Just call us</p>
            <p><br></p>
            <p><strong>Human</strong></p>
            <p>Professionalism with a personal touch.</p>
            <p><br></p>
            <p><strong>Transparent</strong></p>
            <p>Knowing how much it costs up front (or as close as dammit). </p>
            <p><br></p>
            <p><strong>Fast</strong></p>
            <p>Leaner fees incentivise us to get the job done.<br>&nbsp</p>
            <p><br></p>
            <p><strong>Innovative</strong></p>
            <p>Embrace innovative systems for the new economy.</p>
            <p><br></p>`,
            TextColumns3: `<h3 class="text-column__title">Transparent Pricing</h3>
            <p>We give each client a carefully considered cost estimate notice up front, and try to limit any surprise costs. You know what you’re in for up front, and if there are variations from the estimate, we’ll talk you through the tradeoffs and implications before you commit.</p>`
        },
        {
          Testimonials: true,
          Title: "What Our Clients Say",
          Decoration: require("@/assets/img/Commas.svg"),
          Testimonial: [
          {
            Content: "Natalie is developing an organisation that could redefine the focus of legal firms. I am looking forward to the many ways we can tackle complex and interesting projects as they keep an eye on our, legally uninformed, enthusiasm.”",
            Author: "<br>– Gavin Julyan, GIG Institute",
            left: true
          },
          {
            Content: "Natalie and I worked together on a sale and acquisition in partnership with a private equity fund. During the project, Natalie availed herself for all our meetings, even on short notice,  and was dedicated to ensuring we met our deadlines. She always provided great insight and considerations during the project.<br><br>Her positive attitude and personality make her a pleasure to work with.”",
            Author: "<br>- Melissa Gangen, Director, Digital Planet",
            left: true
          },
          {
            Content: "Natalie and her team have consistently provided us with sound legal advice and have developed legal strategies that are time sensitive and focussed on the desired outcome. We would not hesitate in recommending NLA to anyone in need of their specialist services.”",
            Author: "<br>- Mark Harris, Financial Manager, King Civil Engineering Contractors",
            left: true
          },
          {
            Content: "After a labour dispute with an employee we approached NLA Legal Inc for advice. We received fast accurate advice and documentation on how to proceed step by step. Not once were we left not knowing what to do and always got a swift reply and answer. Storage spot would like to thank NLA Legal for the amazing service.”",
            Author: "<br>- Karen Bryant, Facility Manager, Storage Spot (Pty) Ltd",
          },
          {
            Content: "On behalf of the South Deep Education Trust and South Deep Community Trust, I would like to commend the NLA Team for ensuring that our Trusts are administered in a manner that safeguards our assets and ensures that those assets are managed with full integrity and transparency while ensuring that tangible benefits accrue to beneficiary communities”.",
            Author: "<br>- Alex Khumalo, Chairperson, South Deep Education Trust and South Deep Community Trust",
          }
          ]
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>